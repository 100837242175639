import React from "react"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { StyledContactSection } from "./ContactSection.styled"

const ContactSection = ({ intl }) => (
  <StyledContactSection className="section">
    <div className="container">
      <h2 className="section__title">
        {intl.formatMessage({ id: "contactsection1" })}
      </h2>
      <Link to="/kontakt/" className="button">{intl.formatMessage({ id: "contactsection2" })}</Link>
    </div>
  </StyledContactSection>
)

export default injectIntl(ContactSection)
