import styled from "@emotion/styled"

export const StyledContactSection = styled.section`
  background-color: #e8e8e8;
  padding: 80px 0;
  text-align: center;

  @media (min-width: 768px) {
    padding: 100px 0;
  }

  @media (min-width: 992px) {
    padding: 120px 0;
  }

  .section__title {
    margin: -6px 0 2rem;
    text-align: center;
    line-height: 1.15;
  }
`
