import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TitleSection from "../components/TitleSection"
import ContactSection from "../components/ContactSection"
import { sectionService } from "../assets/style/pages/service.module.scss"

const ServicesPage = ({ data, intl }) => {
  const serviceData = data.allCockpitServices.edges.map(({ node }) => ({
    id: node.id,
    icon: node.icon.value.url,
    title: node.title.value,
    description: node.description.value,
    background: node.background.value.childImageSharp.fluid,
    realizationCategory: node.realizationCategory.value.slug.value
  }))[0];

  return (
    <Layout>
      <Seo lang={intl.locale} title={serviceData.title} />
      <TitleSection title={serviceData.title} bg={serviceData.background}></TitleSection>
      <section className={sectionService}>
        <div className="container">
          <img src={serviceData.icon} alt={serviceData.title} />
          <p dangerouslySetInnerHTML={{ __html: serviceData.description }}></p>
          <Link to="/realizacje" state={{ category: serviceData.realizationCategory }} className="button">{intl.formatMessage({ id: "seerealizations" })}</Link>
        </div>
      </section>
      <ContactSection />
    </Layout>
  )
}

export const query = graphql`
  query($id: String, $lng: String) {
    allCockpitServices(filter: {cockpitId: {eq: $id}, lang: {eq: $lng}}) {
      edges {
        node {
          background {
            value {
              childImageSharp {
                fluid(maxWidth: 2560) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          icon {
            value {
              url
            }
          }
          title {
            value
          }
          description {
            value
          }
          realizationCategory {
            value {
              slug {
                value
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(ServicesPage)
